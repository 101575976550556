import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Homey MQTT Client TLS Encryption",
  "path": "/Frequently_Asked_Question/Homey_MQTT_Client_TLS_Encryption/",
  "dateChanged": "2020-04-14",
  "author": "Mike Polinowski",
  "excerpt": "I want to make sure that my MQTT network uses TLS encryption. How do I have to configure my camera and the Homey MQTT Client to accomplish that?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "Homey"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Homey MQTT Client TLS Encryption' dateChanged='2020-04-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to make sure that my MQTT network uses TLS encryption. How do I have to configure my camera and the Homey MQTT Client to accomplish that?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Homey_MQTT_Client_TLS_Encryption/' locationFR='/fr/Frequently_Asked_Question/Homey_MQTT_Client_TLS_Encryption/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "homey-mqtt-client-tls-encryption",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#homey-mqtt-client-tls-encryption",
        "aria-label": "homey mqtt client tls encryption permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Homey MQTT Client TLS Encryption`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to make sure that my MQTT network uses TLS encryption. How do I have to configure my camera and the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/"
      }}>{`Homey MQTT Client`}</a>{` to accomplish that?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Your camera uses a different port for the TLS encrypted MQTT communication - `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`the defaults are`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`regular MQTT Port: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1883`}</code></li>
      <li parentName="ul">{`TLS MQTT Port: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8883`}</code></li>
    </ul>
    <p>{`Make sure that you use the correct TLS port you set `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`inside your camera's WebUI`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "646px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1495162b4b8bac4e8dec05311f7c32ff/27524/Homey_MQTT_Client_TLS_Encryption_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACpklEQVQ4y4WS22oTQRyH8zZie6WxOWjTHLq7s7vuzs7snLKnHNokDYmprW0RURCkUtMiXmiUQkHshXgtIlJpQexbtIjglW8hm0mTVqh+/C6GZT/mf5jEjZJrruwpnUGpMyi0/5P80gD0XhvLb6zV3SvJfCJHlzvvfy/unYa7p/TVCRucXhY+OHVfnnT2f3bf/bj34df0HEmksjk3aLpBkwQN7NVRuY7/GZtX7aBrea18oZhIpWbCwGs2FhbrNcEoJZi6yMXOZSEYeq11GiyoSiGRTqeFEN6QIAjCMGScY4zdIXiCi7GLEGaM3+l2GaOKpsSy1Hzfl3LsMyZlznmlUgmD0ONiTLlcpvRM9n0//iMMoyiilDLGCCFShhBC2y4AcFO9AIRQBeoFOQxD3/fHNRNCDF2fLRaj5MzatWR6Qso0TU3XEplMhg2Rdwoh3HM4NtQsq1dSNgslMEY3oIMmN9eGcM4RQudlQgij1OHcEoJLGBVLD0jYUtTiaGC1Ws33/XGrY9O2bcM0VUXRlHOoADrOZNphGHqe516EEAIAyOVy2WxW9prJxEmnUqZpjGRZDqVULhQhdDYzAqENLcs0dF1TAdBVYCqaoQITOmheKcVyuVyWS5bbjqIormLYg42oZlMdMt2CqPko2vrcePYxePrJra0WC7dGZVer1UqlIjUqIa4JyWbbOt5GB31x9ERZ2XhI+seNF0di+xte2CjMZUdly21xzgkhsmZCXMshG3Vr/76xt2G/XVNbrabV2+F3t1Cvj/3FfD4X75lSKvscPeLxwFx0m0aK6OiiPS86wiFtQ21a+pKheQ6czedjWU7rbxinyGLdTX/ne+v5V9w/fOz1DkvgC/YOFGsdounr1xPJZBIAoF0K0IAuMzxpuqrp8Vft6tTUH+sbhDrZXxZHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1495162b4b8bac4e8dec05311f7c32ff/e4706/Homey_MQTT_Client_TLS_Encryption_02.avif 230w", "/en/static/1495162b4b8bac4e8dec05311f7c32ff/d1af7/Homey_MQTT_Client_TLS_Encryption_02.avif 460w", "/en/static/1495162b4b8bac4e8dec05311f7c32ff/f1214/Homey_MQTT_Client_TLS_Encryption_02.avif 646w"],
              "sizes": "(max-width: 646px) 100vw, 646px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1495162b4b8bac4e8dec05311f7c32ff/a0b58/Homey_MQTT_Client_TLS_Encryption_02.webp 230w", "/en/static/1495162b4b8bac4e8dec05311f7c32ff/bc10c/Homey_MQTT_Client_TLS_Encryption_02.webp 460w", "/en/static/1495162b4b8bac4e8dec05311f7c32ff/8c2f2/Homey_MQTT_Client_TLS_Encryption_02.webp 646w"],
              "sizes": "(max-width: 646px) 100vw, 646px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1495162b4b8bac4e8dec05311f7c32ff/81c8e/Homey_MQTT_Client_TLS_Encryption_02.png 230w", "/en/static/1495162b4b8bac4e8dec05311f7c32ff/08a84/Homey_MQTT_Client_TLS_Encryption_02.png 460w", "/en/static/1495162b4b8bac4e8dec05311f7c32ff/27524/Homey_MQTT_Client_TLS_Encryption_02.png 646w"],
              "sizes": "(max-width: 646px) 100vw, 646px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1495162b4b8bac4e8dec05311f7c32ff/27524/Homey_MQTT_Client_TLS_Encryption_02.png",
              "alt": "Homey MQTT Client TLS Encryption",
              "title": "Homey MQTT Client TLS Encryption",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now activate the secure connection to your broker (TLS) and disable checking for the validity of your certificate - as this would fail for self-signed certificates (since we created this certificate ourself `}<strong parentName="p">{`this is not a security risk`}</strong>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "717px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/b0abb/Homey_MQTT_Client_TLS_Encryption_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe5qaAJQA//EABUQAQEAAAAAAAAAAAAAAAAAAAEw/9oACAEBAAEFAiP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAZEAEAAgMAAAAAAAAAAAAAAAABABAhUWH/2gAIAQEAAT8h2JmgOwov/9oADAMBAAIAAwAAABDEzzz/xAAVEQEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAwEBPxCD/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARMRAhQVFhcYGh/9oACAEBAAE/EFahnmmCsV9hfNSt0dlgDF+9No+df//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/e4706/Homey_MQTT_Client_TLS_Encryption_01.avif 230w", "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/d1af7/Homey_MQTT_Client_TLS_Encryption_01.avif 460w", "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/86621/Homey_MQTT_Client_TLS_Encryption_01.avif 717w"],
              "sizes": "(max-width: 717px) 100vw, 717px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/a0b58/Homey_MQTT_Client_TLS_Encryption_01.webp 230w", "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/bc10c/Homey_MQTT_Client_TLS_Encryption_01.webp 460w", "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/4bde0/Homey_MQTT_Client_TLS_Encryption_01.webp 717w"],
              "sizes": "(max-width: 717px) 100vw, 717px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/e83b4/Homey_MQTT_Client_TLS_Encryption_01.jpg 230w", "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/e41a8/Homey_MQTT_Client_TLS_Encryption_01.jpg 460w", "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/b0abb/Homey_MQTT_Client_TLS_Encryption_01.jpg 717w"],
              "sizes": "(max-width: 717px) 100vw, 717px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3e12aa1fa00d8c7d7f3cb8ba60eab7ab/b0abb/Homey_MQTT_Client_TLS_Encryption_01.jpg",
              "alt": "Homey MQTT Client TLS Encryption",
              "title": "Homey MQTT Client TLS Encryption",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All communication with between Homey and your camera is now safely encrypted.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      